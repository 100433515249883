export default class test {
  constructor() {
    this.input = {
      description: "",
      tags: [],
      title: "",
      typeId: 0,
      url: "",
    };
    this.tagInput = {
      name: "",
    };
    this.body = [];
    this.tags = [];
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      this.input.description = data.description;
      this.input.tags = data.tags;
      this.input.title = data.title;
      this.input.typeId = data.typeId;
      this.input.url = data.url;
      this.input.id = data.id;
    }
    return this.input;
  }
  setTag(mode, data) {
    if (mode === "Сохранить") {
      this.tagInput.id = data.id;
      this.tagInput.name = data.name;
    }
    return this.tagInput;
  }
  setTags(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.tags.push({
          fields: [
            {
              id: 1,
              name: data[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data[i].name,
              type: "string",
            },
          ],
        });
      }
    }
    return this.tags;
  }
  setBody(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.body.push({
          fields: [
            {
              id: 1,
              name: data[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data[i].title,
              type: "string",
            },
            {
              id: 3,
              name: data[i].description,
              type: "string",
            },
            {
              id: 4,
              name: data[i].tags
                .map((x) => {
                  return x.name;
                })
                .toString(),
              type: "string",
            },
            {
              id: 5,
              name: data[i].url,
              type: "string",
            },
            {
              id: 6,
              name: data[i].seoType !== null ? data[i].seoType.title : null,
              type: "string",
            },
          ],
        });
      }
    }
    return this.body;
  }
}
