<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton"
    @edit-button="editButton"
  >
    <v-text-field
      v-model="input.title"
      label="Название"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-text-field
      v-model="input.description"
      label="Описание"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-text-field v-model="input.url" label="URL"></v-text-field>
    <v-select
      v-model="input.tags"
      label="Теги"
      :items="SEO.tagsAll"
      item-text="name"
      item-value="id"
      return-object
      multiple
      :rules="[$validate.required]"
    >
    </v-select>
    <v-select
      v-model="input.typeId"
      label="Тип"
      :items="SEO.seoTypes"
      item-text="name"
      item-value="id"
      :rules="[$validate.required]"
    >
    </v-select>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import seo from "@/model/seo";
export default {
  components: { SingleComponent },
  data() {
    return {
      loading: true,
      mode: "Добавить",
      input: {},
    };
  },
  computed: {
    ...mapGetters({
      SEO: "Seo/STATE",
    }),
  },
  async created() {
    let body = {};
    await this.getTags();
    if (this.$route.params.id) {
      this.mode = "Сохранить";
      body = await this.getById(this.$route.params.id);
    }
    let model = new seo();
    this.input = model.setInput(this.mode, body.data);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getTags: "Seo/GET_TAGS",
      addSeo: "Seo/SAVE_SEO",
      editSeo: "Seo/UPDATE_SEO",
      getById: "Seo/GET_BY_ID",
    }),
    async addButton() {
      this.loading = true;
      let response = await this.addSeo(this.input);
      if (response.type === "success") {
        this.$router.push("/seo");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.message,
        });
      }
      this.loading = false;
    },
    async editButton() {
      this.loading = true;
      let response = await this.editSeo(this.input);
      if (response.type === "success") {
        this.$router.push("/seo");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.message,
        });
      }
      this.loading = false;
    },
  },
};
</script>
